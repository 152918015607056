import Form, { Input } from 'components/Form'
import { useHistory, useParams } from 'react-router-dom'
import TableWithItems from '../TableWithItens'
import { BsCircle, BsCheckCircle } from 'react-icons/bs'
import { Stepper } from '../Stepper'
import { Card, ButtonCircle, CardSubText } from './styles'
import { useEffect, useState, useRef } from 'react'
import api from 'services/api'
import {
  apiCreate,
  apiCreateFinancialMoviment,
  apiGetPdfFinancialMoviment,
  apiGetPdfPreviewContract,
  apiListMenuFoodDishes,
  apiListMenuFoodTypes,
  apiListPlans
} from '../../domain/api'
import { AxiosResponse } from 'axios'
import {
  genericMaskPercentage,
  currencyMask,
  convertValueMaskInNumberWithTwoZero,
  convertValueMaskInNumberWithTwoZeroDigits,
  percentualMask
} from 'utlis/mask'

import ToolTipError from 'components/ToolTipError'

import { useLoading } from 'hooks/loading'
import Modal from 'components/Modal'
import { useToast } from 'hooks/toast'
import { Alert } from 'components/Alert'
import {
  calculateDiscountToBRL,
  calculateValueWithDiscountToBRL,
  calculateValueWithDiscountToNumber
} from 'utlis/calculateDiscount'
import Tooltip from 'components/Tooltip'
import { AiOutlineQuestionCircle } from 'react-icons/ai'
import Summary from '../Summary'
import { InfoContract } from '../InfoContract'
import ChooseDaysFrequencyForm from '../ChooseDaysFrequencyForm'
import { FormClient } from 'pages/Commercial/Clients/Register/components/Form'
import { FormStudent } from 'pages/Commercial/Clients/Students/components/Form'
import { useAuth } from 'hooks/auth'
import moment from 'moment'
import {
  calculateAutomaticDiscount,
  IAutomaticDiscount,
  messagesPlanDiscount
} from '../../utils/calculateAutomaticDiscount'
import { FinishedPage } from 'pages/Commercial/Finished'

import logo from 'assets/image/merenderia.png'
import { FaEdit } from 'react-icons/fa'
import { getFirstWorkingDay } from 'utlis/date'

const FormRegister = () => {
  const { institution } = useParams<{ institution: string }>()
  const { setToken } = useAuth()
  // INITIAL DATA
  const [hasInstitution, setHasInstitution] = useState(true)
  const [institutionData, setInstitutionData] = useState<IInstitutionData>()
  const [studentData, setStudentData] = useState<IStudent>()
  const [clientData, setClientData] = useState<IClientData>()
  const [defaultValues, setDefaultValues] = useState<any>()
  const [menuFoodTypeData, setMenuFoodTypeData] = useState<IMenuFoodType[]>()
  const [optionsMenuFoodType, setOptionsMenuFoodTypeData] = useState<
    { value: string; name: string }[]
  >([])
  const [allPlans, setAllPlans] = useState<IPlan[]>(undefined)
  const [allPlansFrequency, setAllPlansFrequency] = useState<IPlanFrequency[]>(
    []
  )
  const [plansFrequency, setPlansFrequency] =
    useState<IPlanFrequency[]>(undefined)
  const [menuDishes, setMenuDishes] = useState<IMenuDish[]>(undefined)
  // SELECTED FORMS
  const [selectedMenuFoodTypeId, setSelectedMenuFoodTypeId] = useState<string>()
  const [selectedMenuFoodType, setSelectedMenuFoodType] =
    useState<IMenuFoodType>()
  const [selectedMenuFoodId, setSelectedMenuFoodId] =
    useState<number>(undefined)
  const [selectedPlanId, setSelectedPlanId] = useState<number>(undefined)
  const [selectedPlanName, setSelectedPlanName] = useState<string>(undefined)
  const [selectedPlanFrequency, setSelectedPlanFrequency] =
    useState<ISelectedPlanFrequency>(undefined)
  const [discount, setDiscount] = useState<string>(undefined)
  const [discountTotal, setDiscountTotal] = useState<string>(undefined)
  const [firstInstallmentValue, setFirstInstallmentValue] = useState<string>()
  const [selectedDishes, setSelectedDishes] =
    useState<ISelectedDish[]>(undefined)
  const [frequencyDaysOfWeek, setFrequencyDaysOfWeek] = useState<string[]>([])
  const [defaultValuesDaysOfWeek, setDefaultValuesDaysOfWeek] = useState<
    string[]
  >([])
  const [isOpenModalFrequency, setIsOpenModalFrequency] =
    useState<boolean>(false)
  const [isOpenModalDetails, setIsOpenModalDetails] = useState<boolean>(false)
  const [isOpenModalTableWithItems, setIsOpenModalTableWithItems] =
    useState<boolean>(false)

  const [selectedMenuFood, setSelectedMenuFood] = useState<IMenuFood>()
  const [actualStep, setActualStep] = useState<TActualStep>('Client')
  const [formErrors, setFormErrors] = useState<IFormErrors>({
    isMenuFoodSelected: true,
    isPlanFrequencySelected: true,
    isPlanSelected: true,
    isAllowedToChangeAllSteps: false,
    isAllowedToChangeStepPayament: false,
    isAllowedToChangeStepSelect: false,
    isAllowedToChangeStepConfirm: false
  })
  const [alert, setAlert] = useState<IContractAlert>({
    message: '',
    isActive: false
  })
  const [contractPlans, setContractPlans] = useState<IContractPlanDisplay[]>([])
  const [menuFood, setMenuFood] = useState<IMenuFood>()
  const [dueDate, setDueDate] = useState<string | Date>()
  const [favoriteDay, setFavoriteDay] = useState<string>()
  const [startDate, setStartDate] = useState<Date>(
    getFirstWorkingDay(
      process.env.REACT_APP_DUE_DATE_LIMIT
        ? Number(process.env.REACT_APP_DUE_DATE_LIMIT)
        : 3
    )
  )
  const [currentYearStart, setCurrentYearStart] = useState<Date>()

  const [selectedPayamentForm, setSelectedPayamentForm] =
    useState<TFormPayament>(undefined)
  const [createdFinancialMoviments, setCreatedFinancialMoviments] =
    useState<IFinancialMoviment[]>()
  const [createdContract, setCreatedContract] = useState<IContract>()
  const [recurrenceNumber, setRecurrenceNumber] = useState<number>()
  const [actualSelectedDishes, setActualSelectedDishes] =
    useState<ISelectedDish[]>()

  const [contractSelected, setContractSelected] = useState(0)
  const [automaticDiscount, setAutomaticDiscount] =
    useState<IAutomaticDiscount>()
  const planValuePayament = calculateValueWithDiscountToBRL(
    discount,
    +selectedPlanFrequency?.value
  )

  const totalPlanFrequency = contractPlans.reduce(
    (accumulator, currentValue) =>
      accumulator + currentValue.value_with_discount,
    0
  )
  const valuePayament = calculateValueWithDiscountToBRL(
    discountTotal,
    totalPlanFrequency
  )

  const handlerClickButtonConfirmAlert = () => {
    setAlert({
      message: '',
      isActive: false
    })
  }

  const refModal = useRef(null)
  const refModalTable = useRef(null)

  const { addToast } = useToast()

  const { disableLoading, activeLoading, loading } = useLoading()

  const getPdfBoleto = (
    financialMovimentPaymentId: number,
    barCode: string
  ) => {
    activeLoading()
    api
      .get(apiGetPdfFinancialMoviment(financialMovimentPaymentId.toString()))
      .then(res => {
        const linkSource = `data:application/pdf;base64,${res.data.pdf}`

        const downloadLink = document.createElement('a')
        const fileName = `Cliente - ${studentData.client_id} Numero -${barCode}.pdf`
        downloadLink.href = linkSource
        downloadLink.download = fileName
        // window.open(linkSource)
        downloadLink.click()

        disableLoading()
      })
  }

  const handleClickOnOpenModalDescription = (menuFood: IMenuFood) => {
    setSelectedMenuFood(menuFood)
    setIsOpenModalDetails(true)
  }
  const handleClickOnCloseModalDescription = () => {
    setIsOpenModalDetails(false)
  }

  const handleClickOnOpenModalTableWithItems = (
    actualDishes: ISelectedDish[]
  ) => {
    setIsOpenModalTableWithItems(true)
    setActualSelectedDishes(actualDishes)
  }

  const handleClickOnCloseModalTableWithItems = () => {
    setIsOpenModalTableWithItems(false)
  }

  const handleClickOnCloseModalFrequency = () => {
    if (!defaultValuesDaysOfWeek.length) {
      setSelectedPlanFrequency(undefined)
    }
    setFrequencyDaysOfWeek(defaultValuesDaysOfWeek)
    setIsOpenModalFrequency(false)
  }

  const handleClickOnConfirmModalFrequency = () => {
    if (contractSelected) {
      setContractPlans(a => {
        const copy: any[] = JSON.parse(JSON.stringify(a))
        const index = copy.findIndex(
          a => a.plan_frequency_id === contractSelected
        )
        if (index === -1) {
          return copy
        }
        copy[index].frequency_days_of_week = JSON.stringify({
          frequency_days_of_week: frequencyDaysOfWeek
        })
        return copy
      })
      setContractSelected(0)
    }
    setDefaultValuesDaysOfWeek([])
    setIsOpenModalFrequency(false)
  }

  const createFinancialMoviment = async (contractData: IContract) => {
    const value = convertValueMaskInNumberWithTwoZero(firstInstallmentValue)
    let financialData: ICreateFinancialMoviment = {
      client_id: studentData.client_id,
      contract_id: contractData.id,
      description: 'Contratação de um plano',
      value,
      nf_status: 'Wainting',
      operation_type: 'C',
      sub_category_id: 1,
      category_id: 1,
      payment_gateway_id: 1, // alterar
      due_date: moment(dueDate, 'DD/MM/YYYY').toDate(),
      period: moment(startDate).format('YYYY-MM'),
      send_email: true
    }
    if (recurrenceNumber > 1) {
      financialData = {
        ...financialData,
        number_recurrence: recurrenceNumber,
        recurrence: true,
        frequency: 'm',
        value: value / recurrenceNumber
      }
    }

    try {
      const resonseSlip = await api.post(
        apiCreateFinancialMoviment(),
        financialData
      )
      setCreatedFinancialMoviments(resonseSlip.data)
      disableLoading()
    } catch (error) {
      disableLoading()
      addToast({
        type: 'error',
        title: 'Não foi possível fazer a criação do contrato.',
        description: 'Por favor, complete todos os dados corretamente.'
      })
    }
  }

  const activePlansByMenuFood = allPlans?.filter(
    ({ menu_food_id, is_active }) =>
      menu_food_id === selectedMenuFoodId && is_active
  )

  const setPlan = (id?: number) => {
    setSelectedPlanId(id)
    setSelectedPlanName(activePlansByMenuFood[0]?.name)
    setSelectedPlanFrequency(undefined)
    setFrequencyDaysOfWeek([])

    setFormErrors(old => ({
      ...old,
      isPlanSelected: true
    }))
  }

  const getItemsMenuFood = (
    institution_id: string,
    menu_food_type_id: string
  ) => {
    api
      .get(apiListMenuFoodDishes(institution_id, menu_food_type_id))
      .then((res: AxiosResponse<IMenuFoodData[]>) => {
        const { data } = res
        setMenuDishes(data?.[0]?.menu_dishes)
        setPlansFrequency(data?.[0]?.plans_frequency)
        setSelectedMenuFoodId(data?.[0]?.id)
        setMenuFood(data?.[0])
        if (data?.[0]?.menu_institutions?.[0]?.current_year_start) {
          setCurrentYearStart(
            moment(
              data?.[0]?.menu_institutions?.[0]?.current_year_start,
              'DD/MM/YYYY HH:mm:ss'
            )
              .add(3, 'h')
              .toDate()
          )
        }
        setPlan(1)
        setAllPlansFrequency(prev => {
          if (data?.[0]?.plans_frequency) {
            return [...prev, ...data?.[0]?.plans_frequency]
          } else {
            return [...prev]
          }
        })

        disableLoading()
      })
  }

  const getClient = async (id: string): Promise<IClientData> => {
    const { data } = await api.get(`/commercial/clients/viewV2Hiring/${id}`)
    return data
  }
  const getStudent = async (id: string): Promise<IStudent> => {
    const { data } = await api.get(`/commercial/students/viewV2Hiring/${id}`)
    return data
  }
  const history = useHistory()
  // const location = new URLSearchParams(history.location.search)

  useEffect(() => {
    ;(async () => {
      let instituitionResponse
      try {
        activeLoading()
        instituitionResponse = await api.get(`sessionsCommon/${institution}`)
        if (instituitionResponse?.data?.token) {
          setToken({ token: instituitionResponse.data.token })
          setInstitutionData(instituitionResponse.data.institution)
        }
        api.get(apiListPlans()).then((res: AxiosResponse<IPlan[]>) => {
          const { data } = res
          setAllPlans(data)
        })
        const location = new URLSearchParams(history.location.search)
        const step = location.get('step')
        const client = location.get('client')
        const student = location.get('student')
        const clientSession = sessionStorage.getItem('@merenderia:client')
        if (step === '2') {
          activeLoading()
          if (client && client === clientSession) {
            const clientData = await getClient(client)
            setClientData(clientData)
            setActualStep('Student')
          }
          disableLoading()
        }
        if (step === '3') {
          activeLoading()
          if (client && student && client === clientSession) {
            const clientData = await getClient(client)
            setClientData(clientData)
            const studentData = await getStudent(student)
            setStudentData(studentData)
            setFormErrors(old => ({
              ...old,
              isAllowedToChangeStepSelect: true
            }))
            setActualStep('Select')
          }
          disableLoading()
        }
        setHasInstitution(true)
        disableLoading()
      } catch (error) {
        disableLoading()
        setHasInstitution(false)
        addToast({
          title: 'Instituição não encontrada',
          type: 'error'
        })
        document.body.style.pointerEvents = 'none'
      }
    })()
  }, [
    activeLoading,
    addToast,
    disableLoading,
    history.location.search,
    institution,
    setToken
  ])

  useEffect(() => {
    if (contractPlans.length > 0 && studentData.client) {
      const calculatedAutomaticDiscount = calculateAutomaticDiscount(
        contractPlans,
        studentData.client,
        studentData
      )
      setAutomaticDiscount(calculatedAutomaticDiscount)
      setDiscountTotal(
        genericMaskPercentage(
          calculatedAutomaticDiscount.automaticDiscountAdded.toString()
        )
      )
    }
  }, [contractPlans, studentData])

  useEffect(() => {
    if (studentData) {
      const activeContracts = studentData?.contracts?.filter(contract =>
        ['Aguardando pagamento', 'Ativo'].includes(contract.situation)
      )
      const menuFoodTypesByStudent: number[] = []
      activeContracts?.forEach(activeContract => {
        activeContract.contract_plans?.forEach(plan => {
          menuFoodTypesByStudent.push(
            plan?.plan_frequency?.menu_food?.menu_food_type_id
          )
        })
      })
      api
        .get(
          apiListMenuFoodTypes(
            studentData.school_period,
            studentData.institution_id
          )
        )
        .then((res: AxiosResponse<IMenuFoodType[]>) => {
          const { data } = res
          setMenuFoodTypeData(data)
          const options = data
            .filter(
              ({ is_active, id }) =>
                is_active && !menuFoodTypesByStudent.includes(id)
            )
            .map(({ id, name }) => {
              return {
                value: id.toString(),
                name: name
              }
            })
            .sort((a, b) => {
              return a.name.localeCompare(b.name)
            })
          setOptionsMenuFoodTypeData(options)
        })
    }
  }, [studentData])

  const alertNotActiveMenuFoodsInMenuFoodType = (selectedId: string) => {
    const isSomeMenuFoodActive = menuFoodTypeData
      ?.find(({ id }) => id === +selectedId)
      ?.menu_foods.some(({ is_active }) => is_active)
    if (!isSomeMenuFoodActive) {
      setAlert({
        isActive: true,
        message:
          'Não há cardápios registrados no tipo selecionado. Por favor, selecione outro tipo de cardápio.'
      })
    }
  }

  const addPlanToContractData = () => {
    if (!selectedPlanId || !selectedPlanFrequency || !selectedMenuFoodId) {
      addToast({
        type: 'error',
        title: 'Não foi possível adicionar o plano.',
        description: 'Por favor, complete a seleção de um plano frequência.'
      })
      setFormErrors({
        isMenuFoodSelected: !!selectedMenuFoodId,
        isPlanFrequencySelected: !!selectedPlanFrequency?.frequency,
        isPlanSelected: !!selectedPlanId,
        isAllowedToChangeStepPayament: false,
        isAllowedToChangeStepSelect: true,
        isAllowedToChangeStepConfirm: false,
        isAllowedToChangeAllSteps: false
      })
      return
    }
    const {
      id,
      periodicity: periodicity_payament,
      value: plan_value
    } = selectedPlanFrequency
    const planContract: IContractPlanDisplay = {
      plan_id: selectedPlanId,
      plan_frequency_id: id,
      discount: convertValueMaskInNumberWithTwoZeroDigits(discount),
      plan_value: +plan_value,
      periodicity_payament,
      plan_name: selectedPlanName,
      value_with_discount: calculateValueWithDiscountToNumber(
        discount,
        +plan_value
      ),
      items_selected: JSON.stringify(selectedDishes),
      frequency_days_of_week: JSON.stringify({
        frequency_days_of_week: frequencyDaysOfWeek
      }),
      menu_food: menuFood,
      menu_food_type_name: selectedMenuFoodType.name
    }

    setContractPlans(old => [...old, planContract])
    addToast({ title: 'Plano adicionado com sucesso!' })
    setSelectedMenuFoodTypeId('')
    setSelectedMenuFoodId(undefined)
    setMenuFood(undefined)
    setSelectedPlanId(undefined)
    setSelectedPlanName(undefined)
    setSelectedPlanFrequency(undefined)
    setSelectedDishes(undefined)
    setDiscount('')
  }

  const onSubmitForm = async (data: IContractForm) => {
    if (actualStep === 'Select' && contractPlans.length === 0) {
      if (!selectedPlanId || !selectedPlanFrequency || !selectedMenuFoodId) {
        addToast({
          type: 'error',
          title: 'Não foi possível realizar o cadastro.',
          description: 'Por favor, preencha todos os campos.'
        })
        setFormErrors({
          isMenuFoodSelected: !!selectedMenuFoodId,
          isPlanFrequencySelected: !!selectedPlanFrequency?.frequency,
          isPlanSelected: !!selectedPlanId,
          isAllowedToChangeStepPayament: true,
          isAllowedToChangeStepSelect: true,
          isAllowedToChangeStepConfirm: true,
          isAllowedToChangeAllSteps: true
        })
        return
      } else {
        addPlanToContractData()
      }
    }

    if (actualStep === 'Client') {
      setActualStep('Student')
      if (clientData?.id) {
        const path = history.location.pathname
        history.replace(`${path}?client=${clientData?.id}&step=2`)
        sessionStorage.setItem('@merenderia:client', String(clientData?.id))
      }
      return
    }
    if (actualStep === 'Student') {
      setActualStep('Select')
      setFormErrors(old => ({ ...old, isAllowedToChangeStepSelect: true }))
      return
    }

    if (actualStep === 'Select') {
      setActualStep('Payament')
      setFormErrors(old => ({ ...old, isAllowedToChangeStepPayament: true }))
      return
    }
    // 2. If all is checked, pass to phase 2
    if (actualStep === 'Payament') {
      if (actualStep === 'Payament') {
        // selectedDishes
        if (!dueDate || !recurrenceNumber || !favoriteDay) {
          addToast({
            type: 'error',
            title: 'Não é possível passar para próxima etapa.',
            description: 'Por favor, preencha todos os campos.'
          })
          return
        }
        setActualStep('Confirm')
        // Api call <- Here ->
      }
      // 3. If all in phase 3 is completed, pass to phase 3
    }
    if (actualStep === 'Confirm') {
      setFormErrors(old => ({ ...old, isAllowedToChangeAllSteps: true }))
      setFormErrors(old => ({ ...old, isAllowedToChangeStepSelect: false }))
      setActualStep('Finish')
    }
  }

  const createContractData = async (): Promise<IContract> => {
    const { client_id, id: student_id, institution_id } = studentData
    const plans_contract: IContractPlanCreate[] = contractPlans.map(
      ({
        periodicity_payament,
        plan_frequency_id,
        plan_id,
        plan_value,
        value_with_discount,
        discount,
        items_selected,
        frequency_days_of_week
      }) => {
        return {
          plan_id,
          plan_frequency_id,
          discount,
          plan_value,
          periodicity_payament,
          value_with_discount,
          items_selected,
          frequency_days_of_week
        }
      }
    )

    const contractData: IContract = {
      client_id,
      student_id,
      institution_id,
      value_without_discount: totalPlanFrequency,
      discount: convertValueMaskInNumberWithTwoZeroDigits(discountTotal),
      value_payament: convertValueMaskInNumberWithTwoZero(valuePayament),
      periodicity_payament: plans_contract[0].periodicity_payament,
      plans_contract,
      form_payament: selectedPayamentForm,
      discounts_add: automaticDiscount.discounts_add,
      favorite_day: favoriteDay,
      start_date: startDate
    }
    return contractData
  }

  const previewContract = async () => {
    activeLoading()
    const dataToGeneratePdfPreview: any = {
      client: { ...studentData.client, students: undefined },
      student: {
        name: studentData.name,
        serie_class: studentData.serie_class,
        student_food_restrictions: studentData.student_food_restrictions
      },
      institution: {
        company_social_name: studentData.institution.company_social_name
      },
      form_payament: selectedPayamentForm,
      value_payament: convertValueMaskInNumberWithTwoZero(valuePayament),
      due_date: moment(dueDate, 'DD/MM/YYYY').toDate(),
      discount: discountTotal,
      created_at: new Date(),
      periodicity_payament: contractPlans?.[0]?.periodicity_payament,
      menu_food_dishes: contractPlans.map(item => ({
        name: item.menu_food_type_name,
        frequency: item.frequency_days_of_week
      }))
    }
    await api
      .post(apiGetPdfPreviewContract(), dataToGeneratePdfPreview, {
        responseType: 'blob'
      })
      .then(res => {
        window.open(URL.createObjectURL(res.data))
        disableLoading()
      })
      .catch(err => console.log(err.response.data))
    disableLoading()
  }
  const confirmContract = async () => {
    const contractData = await createContractData()
    activeLoading()
    await api
      .post(apiCreate(), contractData)
      .then(async res => {
        addToast({
          type: 'success',
          title: 'Contrato registrado com sucesso.'
        })
        setCreatedContract(res.data)
        if (selectedPayamentForm === 'Boleto') {
          await createFinancialMoviment(res.data)
          onSubmitForm(undefined)
        }
      })
      .catch(() => {
        addToast({
          type: 'error',
          title: 'Erro ao registrar contrato. Tento novamente mais tarde!.'
        })
      })
    disableLoading()
  }

  const changeStep = (step: TActualStep) => {
    if (step === 'Payament') {
      return
    }
    if (step === 'Select' && formErrors.isAllowedToChangeStepSelect) {
      setActualStep(step)
    }
    if (step === 'Student' && formErrors.isAllowedToChangeStepSelect) {
      setContractPlans([])
      setActualStep(step)
    }
    if (step === 'Finish' && formErrors.isAllowedToChangeAllSteps) {
      setActualStep(step)
    }
    if (step === 'Confirm' && formErrors.isAllowedToChangeStepConfirm) {
      setActualStep(step)
    }
  }
  useEffect(() => {
    setDefaultValues({
      client_name: clientData?.name,
      student_name: studentData?.name,
      class: studentData?.serie_class?.name,
      institution_social_name: institutionData?.company_social_name
    })
  }, [studentData, institutionData, clientData])

  if (
    !loading &&
    (!hasInstitution || !institutionData?.series_classes?.length)
  ) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '200px 0px'
        }}
      >
        <img
          src={logo}
          alt="Merenderia-logo"
          style={{ marginBottom: '15px', width: '200px' }}
        />
        {hasInstitution && !institutionData?.series_classes?.length ? (
          <h1>Turmas não cadastradas para essa instituição</h1>
        ) : (
          <h1>Instituição não encontrada!</h1>
        )}
        <p>
          A URL que você tentou acessar não existe. Por favor, utilize uma url
          válida
        </p>
      </div>
    )
  }

  return (
    <div>
      <img
        src={logo}
        alt="Merenderia-logo"
        style={{ marginBottom: '15px', width: '200px' }}
      />
      <hr style={{ color: '#EFF2F5', height: '2px' }} />
      <Stepper
        steps={[
          {
            status:
              actualStep === 'Client'
                ? 'current'
                : actualStep === 'Confirm' || actualStep === 'Payament'
                ? 'completed'
                : '',
            step: '1',
            title: 'Cliente',
            step_name: 'Client'
            // changeStep: setActualStep('Select')
          },
          {
            status:
              actualStep === 'Student'
                ? 'current'
                : actualStep === 'Confirm' || actualStep === 'Payament'
                ? 'completed'
                : '',
            step: '2',
            title: 'Estudante',
            step_name: 'Student'
            // changeStep: setActualStep('Select')
          },
          {
            status:
              actualStep === 'Select'
                ? 'current'
                : actualStep === 'Confirm' || actualStep === 'Payament'
                ? 'completed'
                : '',
            step: '3',
            title: 'Escolha a Refeição',
            step_name: 'Select'
            // changeStep: setActualStep('Select')
          },
          {
            status:
              actualStep === 'Payament'
                ? 'current'
                : actualStep === 'Confirm'
                ? 'completed'
                : '',
            step: '4',
            title: 'Pagamento',
            step_name: 'Payament'
          },
          {
            status: actualStep === 'Confirm' ? 'current' : '',
            step: '5 ',
            title: 'Confirmação',
            step_name: 'Confirm'
          },
          {
            status: actualStep === 'Finish' ? 'current' : '',
            step: '6 ',
            title: 'Finalização',
            step_name: 'Finish'
          }
        ]}
        changeStep={changeStep}
      />
      {actualStep === 'Client' && (
        <FormClient
          typeForm="create"
          setClient={setClientData}
          onSubmit={onSubmitForm}
          institutionName={institutionData?.company_social_name}
        />
      )}
      {actualStep === 'Student' && (
        <FormStudent
          typeForm="create"
          clientInfo={{ id: clientData?.id, name: clientData.name }}
          updateOnSubmit={() => {
            //
          }}
          initialValues={{
            institution_id: institutionData?.id,
            institution_name: institutionData?.company_social_name,
            institution_series_classes: institutionData?.series_classes
          }}
          setStudent={setStudentData}
          studentList={clientData?.students?.filter(
            student =>
              student?.institution_id === institutionData?.id ||
              student?.contracts?.some(contract =>
                ['Finalizado', 'Cancelado'].includes(contract.situation)
              )
          )}
          onSubmit={onSubmitForm}
        />
      )}
      <Form
        onSubmit={onSubmitForm}
        setReset
        defaultValues={{
          ...defaultValues
        }}
      >
        <div className="card mb-5 mb-xl-10">
          {actualStep === 'Select' && (
            <div id="select__form__menu-food">
              <h4>{institutionData?.company_social_name}</h4>
              <div className="row">
                <Input
                  name="client_name"
                  className={'col-md-4'}
                  disabled
                  label="Cliente"
                />
                <Input
                  name="student_name"
                  className={'col-md-4'}
                  label="Estudante"
                  disabled
                />
                <Input
                  name="class"
                  className={'col-md-4'}
                  label="Ano/Turma"
                  disabled
                />
              </div>
              <div className="separator my-5" />
              <div className="row">
                <div>
                  <div>
                    <h3 className="col-form-label fw-bold fs-6 ">
                      Tipo de refeição
                    </h3>
                  </div>
                </div>
                {optionsMenuFoodType
                  .filter(omf => {
                    const optionsSelected = contractPlans.map(
                      cp => cp.menu_food_type_name
                    )
                    return !optionsSelected.includes(omf.name)
                  })
                  .map(optionsMenuFoodType => (
                    <div
                      className="col-xl-3 col-sm-4 mb-5"
                      key={optionsMenuFoodType.value}
                    >
                      <Card
                        isSelected={
                          optionsMenuFoodType.value === selectedMenuFoodTypeId
                        }
                        className="d-flex align-items-center flex-column gap-4"
                        height={80}
                        height_big_screen={90}
                      >
                        <ButtonCircle
                          type="button"
                          onClick={() => {
                            setSelectedMenuFoodTypeId(optionsMenuFoodType.value)
                            setSelectedMenuFoodType(
                              menuFoodTypeData?.find(
                                a => String(a?.id) === optionsMenuFoodType.value
                              )
                            )
                            setSelectedMenuFoodId(undefined)
                            setMenuFood(undefined)
                            setSelectedPlanId(undefined)
                            setSelectedPlanFrequency(undefined)
                            alertNotActiveMenuFoodsInMenuFoodType(
                              optionsMenuFoodType.value
                            )
                            getItemsMenuFood(
                              String(studentData?.institution?.id),
                              String(optionsMenuFoodType.value)
                            )
                          }}
                          style={{ width: '22px', height: '22px' }}
                        >
                          {optionsMenuFoodType.value ===
                          selectedMenuFoodTypeId ? (
                            <BsCheckCircle fontSize={22} color="#009EF7" />
                          ) : (
                            <BsCircle fontSize={20} color="#A1A5B7" />
                          )}
                        </ButtonCircle>
                        <h3 style={{ color: '#3F4254' }}>
                          {optionsMenuFoodType.name}
                        </h3>
                      </Card>
                    </div>
                  ))}
                {optionsMenuFoodType.filter(omf => {
                  const optionsSelected = contractPlans.map(
                    cp => cp.menu_food_type_name
                  )
                  return !optionsSelected.includes(omf.name)
                }).length === 0 && (
                  <h4>
                    Todas as refeições disponíveis para este estudante já foram
                    selecionadas.
                  </h4>
                )}
              </div>
              {/*               {selectedMenuFoodTypeId && (
                <>
                  <div className="separator my-5" />
                  <div className="row mb-5">
                    <div>
                      <div>
                        <h3
                          className={`col-form-label fw-bold fs-6
                       ${!formErrors.isMenuFoodSelected && 'text-danger'}`}
                        >
                          Cardápio
                          <span style={{ position: 'relative' }}>
                            {!formErrors.isMenuFoodSelected && (
                              <ToolTipError
                                title={'A seleção de um cardápio é obrigatória'}
                                position="right"
                              />
                            )}
                          </span>
                        </h3>
                      </div>
                    </div>
                    <div className="row">
                      {activeMenuFoodsByMenuType &&
                        activeMenuFoodsByMenuType.length === 0 && (
                          <div>
                            Não há cardápios registrados no tipo selecionado.
                          </div>
                        )}
                      <>
                        <p>{activeMenuFoodsByMenuType?.[0]?.description}</p>
                        {menuDishes && (
                          <p style={{ marginTop: '15px' }}>
                            Selecione os items do cardápio:
                          </p>
                        )}
                        <ChooseItemsForm
                          menuDishes={menuDishes}
                          setSelectedDishes={setSelectedDishes}
                          selectedDishes={selectedDishes}
                          handleClickOnClose={handleClickOnClose}
                          setPlanId={setPlan}
                        />
                      </>
                    </div>
                  </div>
                </>
              )}
 */}
              {selectedPlanId && (
                <>
                  <p className="col-form-label fw-bold fs-6">
                    Descrição do cardápio:
                  </p>
                  <p className="col-form-label fw-bold fs-4">
                    {menuFood?.description}
                  </p>

                  <div className="separator my-5" />
                  <div className="row mb-5">
                    <div>
                      <div>
                        <h3
                          className={`col-form-label fw-bold fs-6
                      ${!formErrors.isPlanFrequencySelected && 'text-danger'}`}
                        >
                          Frequência e valores
                          <span style={{ position: 'relative' }}>
                            {!formErrors.isPlanFrequencySelected && (
                              <ToolTipError
                                title={
                                  'A seleção de uma frequência é obrigatória'
                                }
                                position="right"
                              />
                            )}
                          </span>
                        </h3>
                      </div>

                      <div className="row">
                        {selectedPlanId &&
                          (plansFrequency?.length === 0 || !plansFrequency) && (
                            <div>
                              Não há planos frequência registrados no tipo
                              selecionado.
                            </div>
                          )}
                        {selectedPlanId &&
                        contractPlans?.length > 0 &&
                        plansFrequency
                          ?.map((a: any) => {
                            const { periodicity } = JSON.parse(
                              a.payament_form
                            ) as {
                              periodicity: TPeriodicity
                              value: string
                            }
                            if (
                              contractPlans.length > 0 &&
                              contractPlans[0].periodicity_payament ===
                                periodicity
                            ) {
                              return 'hasItems'
                            } else {
                              return 'noItems'
                            }
                          })
                          .find(a => a === 'hasItems') !== 'hasItems' ? (
                          <p>
                            Não existe plano{' '}
                            {contractPlans[0]?.periodicity_payament} para esta
                            refeição.
                          </p>
                        ) : (
                          <></>
                        )}
                        {selectedPlanId ? (
                          Object.entries(
                            plansFrequency
                              ?.sort((a, b) => {
                                const paymentFormA = JSON.parse(
                                  a.payament_form
                                ) as {
                                  periodicity: TPeriodicity
                                }
                                const paymentFormB = JSON.parse(
                                  b.payament_form
                                ) as {
                                  periodicity: TPeriodicity
                                }
                                if (
                                  paymentFormA.periodicity === 'Mensal' &&
                                  paymentFormB.periodicity !== 'Mensal'
                                ) {
                                  return -1
                                }
                                if (
                                  paymentFormA.periodicity === 'Trimestral' &&
                                  !['Trimestral', 'Mensal'].includes(
                                    paymentFormB.periodicity
                                  )
                                ) {
                                  return -1
                                }
                                if (
                                  paymentFormA.periodicity === 'Semestral' &&
                                  ![
                                    'Semestral',
                                    'Trimestral',
                                    'Mensal'
                                  ].includes(paymentFormB.periodicity)
                                ) {
                                  return -1
                                }
                                if (
                                  paymentFormA.periodicity ===
                                  paymentFormB.periodicity
                                ) {
                                  return (
                                    Number(a.frequency.charAt(0)) -
                                    Number(b.frequency.charAt(0))
                                  )
                                }
                                return 0
                              })
                              .reduce(function (r, a) {
                                const { periodicity } = JSON.parse(
                                  a.payament_form
                                ) as {
                                  periodicity: TPeriodicity
                                }
                                r[periodicity] = r[periodicity] || []
                                r[periodicity].push(a)
                                return r
                              }, {} as { [key: string]: any[] })
                          ).map(([key, values]) => {
                            const hasItems = values.some(a => {
                              const { payament_form } = a
                              const { periodicity } = JSON.parse(
                                payament_form
                              ) as {
                                periodicity: TPeriodicity
                                value: string
                              }
                              return (
                                contractPlans[0]?.periodicity_payament ===
                                periodicity
                              )
                            })
                            return (
                              <>
                                {(hasItems || !contractPlans.length) && (
                                  <p className="col-form-label fw-bold fs-4">
                                    {key}
                                  </p>
                                )}
                                {values.map((i: any) => {
                                  const { id, frequency, payament_form } = i
                                  const { periodicity, value } = JSON.parse(
                                    payament_form
                                  ) as {
                                    periodicity: TPeriodicity
                                    value: string
                                  }
                                  if (contractPlans?.length > 0) {
                                    if (
                                      contractPlans[0].periodicity_payament !==
                                      periodicity
                                    ) {
                                      return <></>
                                    }
                                  }
                                  return (
                                    <div
                                      className="col-xl-3 col-sm-4 mb-5"
                                      key={'plan_frequency' + id}
                                    >
                                      <Card
                                        isSelected={
                                          id === selectedPlanFrequency?.id
                                        }
                                        className="d-flex align-items-center flex-column gap-4"
                                      >
                                        <ButtonCircle
                                          type="button"
                                          onClick={() => {
                                            if (
                                              id !== selectedPlanFrequency?.id
                                            ) {
                                              setSelectedPlanFrequency({
                                                id,
                                                frequency,
                                                value,
                                                periodicity
                                              })
                                              setFrequencyDaysOfWeek([])
                                              setDefaultValuesDaysOfWeek([])

                                              setFormErrors(old => ({
                                                ...old,
                                                isPlanFrequencySelected: true
                                              }))
                                              if (
                                                Number(frequency.charAt(0)) ===
                                                5
                                              ) {
                                                setFrequencyDaysOfWeek([
                                                  'Segunda-feira',
                                                  'Terça-feira',
                                                  'Quarta-feira',
                                                  'Quinta-feira',
                                                  'Sexta-feira'
                                                ])
                                                return
                                              }
                                              setIsOpenModalFrequency(true)
                                            }
                                          }}
                                          style={{
                                            width: '22px',
                                            height: '22px'
                                          }}
                                        >
                                          {id === selectedPlanFrequency?.id ? (
                                            <BsCheckCircle
                                              fontSize={22}
                                              color="#009EF7"
                                            />
                                          ) : (
                                            <BsCircle
                                              fontSize={20}
                                              color="#A1A5B7"
                                            />
                                          )}
                                        </ButtonCircle>
                                        <p className="fs-5 fw-bold text-center">
                                          {frequency}
                                        </p>
                                        <p className="fs-5 fw-bold text-primary">
                                          {currencyMask(value)}
                                          <span className="">
                                            /{periodicity}
                                          </span>
                                        </p>
                                        {id === selectedPlanFrequency?.id && (
                                          <p>
                                            {frequencyDaysOfWeek.map(a => (
                                              <span
                                                key={a}
                                                style={{ marginRight: '5px' }}
                                              >
                                                {a}
                                              </span>
                                            ))}
                                          </p>
                                        )}
                                        {id === selectedPlanFrequency?.id && (
                                          <p
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                              if (
                                                id === selectedPlanFrequency?.id
                                              ) {
                                                setSelectedPlanFrequency({
                                                  id,
                                                  frequency,
                                                  value,
                                                  periodicity
                                                })
                                                setFrequencyDaysOfWeek([])
                                                setFormErrors(old => ({
                                                  ...old,
                                                  isPlanFrequencySelected: true
                                                }))
                                                setIsOpenModalFrequency(true)
                                                setDefaultValuesDaysOfWeek(
                                                  frequencyDaysOfWeek
                                                )
                                              }
                                            }}
                                          >
                                            <FaEdit
                                              style={{ marginRight: '5px' }}
                                            />
                                            Editar dias da semana
                                          </p>
                                        )}
                                      </Card>
                                    </div>
                                  )
                                })}
                              </>
                            )
                          })
                        ) : (
                          <div>Selecione um plano</div>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}

              {false && (
                <div className="row">
                  <Input
                    name="plan_value"
                    className="col-md-3 col-xl-2"
                    label="Valor do plano"
                    value={currencyMask(selectedPlanFrequency?.value)}
                    controlled
                    disabled
                  />
                  <Input
                    name="plan_discount"
                    className="col-md-3 col-xl-2"
                    label="Percentual do desconto"
                    endMessage="%"
                    // rules={{ required: true }}
                    value={discount}
                    onChange={event =>
                      setDiscount(genericMaskPercentage(event.target.value))
                    }
                  />
                  <Input
                    name="plan_discount_value"
                    className="col-md-3 col-xl-2"
                    label="Valor do desconto"
                    value={calculateDiscountToBRL(
                      discount,
                      +selectedPlanFrequency.value
                    )}
                    // value={discountPlanValue()}
                    controlled
                    disabled
                  />
                  <Input
                    name="plan_value_payament"
                    className="col-md-3 col-xl-2"
                    label="Valor total"
                    value={planValuePayament}
                    controlled
                    disabled
                  />
                </div>
              )}

              {selectedPlanFrequency && (
                <div className="d-flex justify-content-end pe-8">
                  <button
                    className="btn btn-primary mb-4 end-0"
                    type="button"
                    onClick={() => addPlanToContractData()}
                  >
                    {contractPlans.length === 0
                      ? 'Adicionar refeição'
                      : 'Adicionar nova refeição'}
                  </button>
                </div>
              )}
              {contractPlans && contractPlans.length > 0 && (
                <div className="d-flex gap-5 align-items-center">
                  <h3
                    className={`col-form-label fw-bold fs-6
                    `}
                  >
                    Cardápios e frequências adicionados
                  </h3>
                  <div className="h-100 d-flex align-items-center w-20">
                    <Tooltip
                      title='Para adicionar um plano, deve ser selecionado primeiramente um tipo de cardápio, que habilitará os cardápios disponíveis para esse tipo. Na sequência, seleciona-se o cardápio, habilitando os planos disponíveis para o cardápio selecionado. Selecionado um plano, escolha a frequência do plano. E por fim, clique no botão "Adicionar novo plano"'
                      widthContainer="auto"
                      width={500}
                    >
                      <AiOutlineQuestionCircle
                        fontSize={18}
                        style={{ position: 'initial' }}
                      />
                    </Tooltip>
                  </div>
                </div>
              )}

              <div className="row mb-5">
                {contractPlans?.map(
                  ({
                    periodicity_payament,
                    plan_value,
                    plan_name,
                    value_with_discount,
                    discount,
                    menu_food,
                    items_selected,
                    frequency_days_of_week,
                    menu_food_type_name,
                    plan_frequency_id
                  }) => {
                    return (
                      <div className="col-xl-3 col-sm-4 mb-5" key={plan_name}>
                        <Card
                          className="d-flex align-items-center flex-column gap-4 h-100 justify-content-between"
                          isSelected
                        >
                          <h3 style={{ color: '#3F4254' }}>
                            {menu_food_type_name}
                          </h3>
                          <p
                            className="fs-7 ps-5 text-primary"
                            onClick={() =>
                              handleClickOnOpenModalDescription(menu_food)
                            }
                            style={{ cursor: 'pointer' }}
                          >
                            Detalhes do cardápio
                          </p>
                          {items_selected && (
                            <CardSubText
                              fontSize="1rem"
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                const selectedDishes =
                                  JSON.parse(items_selected)
                                handleClickOnOpenModalTableWithItems(
                                  selectedDishes
                                )
                              }}
                            >
                              Itens selecionados
                            </CardSubText>
                          )}
                          <CardSubText fontSize="1rem">
                            {periodicity_payament}
                          </CardSubText>
                          <CardSubText fontSize="1rem">
                            {frequency_days_of_week
                              ? JSON.parse(
                                  frequency_days_of_week
                                ).frequency_days_of_week.map((a: any) => (
                                  <span key={a} style={{ padding: '5px' }}>
                                    {a}
                                  </span>
                                ))
                              : ''}
                          </CardSubText>
                          <p
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              const planFrequency = allPlansFrequency?.find(
                                planFrequency =>
                                  planFrequency.id === plan_frequency_id
                              )
                              const { periodicity, value } = JSON.parse(
                                planFrequency.payament_form
                              ) as {
                                periodicity: TPeriodicity
                                value: string
                              }
                              setSelectedPlanFrequency({
                                id: planFrequency.id,
                                frequency: planFrequency.frequency,
                                value,
                                periodicity
                              })
                              setFrequencyDaysOfWeek([])
                              setFormErrors(old => ({
                                ...old,
                                isPlanFrequencySelected: true
                              }))
                              setIsOpenModalFrequency(true)
                              const selectedFrequency = frequency_days_of_week
                                ? JSON.parse(frequency_days_of_week)
                                    .frequency_days_of_week
                                : []
                              setDefaultValuesDaysOfWeek(selectedFrequency)
                              setContractSelected(plan_frequency_id)
                            }}
                          >
                            <FaEdit style={{ marginRight: '5px' }} />
                            Editar dias da semana
                          </p>
                          <CardSubText fontSize="1rem">
                            Valor do Plano {currencyMask(plan_value)}
                          </CardSubText>
                          {discount > 0 && (
                            <CardSubText fontSize="1rem">
                              `Desconto $
                              {calculateDiscountToBRL(
                                discount.toString(),
                                plan_value
                              )}{' '}
                              (${percentualMask(discount)})`
                            </CardSubText>
                          )}
                          <CardSubText
                            fontSize="1rem"
                            className="fw-bold text-primary"
                          >
                            Valor Final {currencyMask(value_with_discount)}
                          </CardSubText>
                          <button
                            type="button"
                            className="btn btn-danger"
                            style={{
                              padding: '5px 8px'
                            }}
                            onClick={() => {
                              setContractPlans(a => {
                                const copy: any[] = JSON.parse(
                                  JSON.stringify(a)
                                )
                                const index = copy.findIndex(
                                  a => a.plan_frequency_id === plan_frequency_id
                                )
                                if (index === -1) {
                                  return copy
                                }
                                copy.splice(index, 1)
                                return copy
                              })
                            }}
                          >
                            Remover refeição
                          </button>
                        </Card>
                      </div>
                    )
                  }
                )}
              </div>
              {false && (
                <>
                  <div className="row">
                    <Input
                      name="value"
                      className="col-md-3 col-xl-3"
                      label="Valor da contratação"
                      // Calcular a soma total
                      value={currencyMask(totalPlanFrequency)}
                      controlled
                      disabled
                    />
                    <Input
                      name="discount"
                      className="col-md-3 col-xl-3"
                      label="Percentual do desconto"
                      endMessage="%"
                      // rules={{ required: true }}
                      value={discountTotal}
                      onChange={event =>
                        setDiscountTotal(
                          genericMaskPercentage(event.target.value)
                        )
                      }
                    />
                    <Input
                      name="discount_value"
                      className="col-md-3 col-xl-3"
                      label="Valor do desconto"
                      value={calculateDiscountToBRL(
                        discountTotal,
                        totalPlanFrequency
                      )}
                      controlled
                      disabled
                    />
                    <Input
                      name="value_payament"
                      className="col-md-3 col-xl-3"
                      label="Valor final da contratação"
                      value={valuePayament}
                      controlled
                      disabled
                    />
                  </div>
                  {
                    <div>
                      {automaticDiscount?.discounts_add?.map(discountType => {
                        return (
                          <p key={discountType}>
                            {messagesPlanDiscount[discountType]}
                          </p>
                        )
                      })}
                    </div>
                  }
                </>
              )}
            </div>
          )}
          {actualStep === 'Payament' && (
            <Summary
              contract_plans={contractPlans}
              total={valuePayament}
              discount={calculateDiscountToBRL(
                discountTotal,
                totalPlanFrequency
              )}
              subTotal={currencyMask(totalPlanFrequency)}
              dueDate={dueDate}
              setDueDate={setDueDate}
              favoriteDay={favoriteDay}
              setFavoriteDay={setFavoriteDay}
              startDate={startDate}
              setStartDate={setStartDate}
              selectedPayamentForm={selectedPayamentForm}
              setSelectedPayamentForm={setSelectedPayamentForm}
              setRecurrenceNumber={setRecurrenceNumber}
              setFirstInstallmentValue={setFirstInstallmentValue}
              currentYearStart={currentYearStart}
            />
          )}
          {actualStep === 'Confirm' && (
            <InfoContract
              discountsAdd={automaticDiscount?.discounts_add}
              studentData={studentData}
              contractPlans={contractPlans}
              createdFinancialMoviments={createdFinancialMoviments}
              subTotal={currencyMask(totalPlanFrequency)}
              discount={calculateDiscountToBRL(
                discountTotal,
                totalPlanFrequency
              )}
              total={valuePayament}
              getPdfBoleto={getPdfBoleto}
              previewContract={previewContract}
              confirmContract={confirmContract}
              handleClickOnOpenModalDescription={
                handleClickOnOpenModalDescription
              }
              handleClickOnOpenModalTableWithItems={
                handleClickOnOpenModalTableWithItems
              }
              firstInstallmentValue={firstInstallmentValue}
            />
          )}
          {actualStep === 'Finish' && (
            <FinishedPage
              getPdfBoleto={getPdfBoleto}
              previewContract={previewContract}
              createdFinancialMoviments={createdFinancialMoviments}
            />
          )}
          <div
            className={`card-footer d-flex py-6 px-9 ${
              actualStep !== 'Payament'
                ? 'justify-content-end'
                : 'justify-content-between'
            }`}
          >
            {['Payament', 'Select'].includes(actualStep) && (
              <button
                style={{
                  marginRight: 'auto'
                }}
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                  changeStep('Select')
                  if (actualStep === 'Select') {
                    changeStep('Student')
                  }
                }}
              >
                Voltar
              </button>
            )}
            {!['Client', 'Student'].includes(actualStep) && (
              <>
                {actualStep !== 'Finish' && actualStep !== 'Confirm' ? (
                  <button type="submit" className="btn btn-primary">
                    Próximo
                  </button>
                ) : (
                  <div></div>
                )}
              </>
            )}
          </div>
        </div>
      </Form>
      {/*       <Modal
        refModal={refModal}
        onClickButtonCancel={handleClickOnClose}
        isOpenModal={isOpenModal}
        pageTitle={`Escolha os itens do ${menuName}`}
        modalWithClose={false}
        Children={
          <ChooseItemsForm
            menuDishes={menuDishes}
            setSelectedDishes={setSelectedDishes}
            selectedDishes={selectedDishes}
            handleClickOnClose={handleClickOnClose}
          />
        }
      />
 */}
      <Modal
        refModal={refModal}
        onClickButtonCancel={handleClickOnCloseModalFrequency}
        isOpenModal={isOpenModalFrequency}
        pageTitle={'Escolha os dias da semana:'}
        modalWithClose={true}
        Children={
          <ChooseDaysFrequencyForm
            frequencyDaysOfWeek={frequencyDaysOfWeek}
            setFrequencyDaysOfWeek={setFrequencyDaysOfWeek}
            handleClickOnCloseModalFrequency={
              handleClickOnConfirmModalFrequency
            }
            selectedPlanFrequency={selectedPlanFrequency}
            defaultValues={defaultValuesDaysOfWeek}
          />
        }
      />
      <Modal
        refModal={refModal}
        onClickButtonCancel={handleClickOnCloseModalDescription}
        isOpenModal={isOpenModalDetails}
        pageTitle={'Detalhes do cardápio'}
        Children={
          <div>
            <p>{selectedMenuFood?.description}</p>
          </div>
        }
      />
      <Modal
        refModal={refModalTable}
        onClickButtonCancel={handleClickOnCloseModalTableWithItems}
        isOpenModal={isOpenModalTableWithItems}
        pageTitle={'Itens escolhidos'}
        Children={
          <TableWithItems actualSelectedDishes={actualSelectedDishes} />
        }
      />
      <Alert
        message={alert.message}
        onlyConfirm
        onClickConfirmButton={() => {
          handlerClickButtonConfirmAlert()
        }}
        onClickCancelButton={() => {
          handlerClickButtonConfirmAlert()
        }}
        isActive={alert.isActive}
      />
    </div>
  )
}

export default FormRegister
